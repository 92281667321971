<template>
	<div class="ele-body">
		<el-tabs>
			<el-card shadow="never">
				<!-- 搜索表单 -->
				<div style="display:flex;justify-content:space-between;align-items:flex-start">
					<el-button @click="dialogFormVisibleAddDiscountActivity=true" class="ele-btn-icon addbtn "
						v-if="permission.includes('sys:change_discounts:add')">添加活动</el-button>
					<el-form :model="table.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table.where.cid"
								@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否审核:" label-width="80px">
							<el-select v-model="table.where.is_check" placeholder="请选择" class="drop-down-third" clearable>
								<el-option :value="1" label="通过"></el-option>
								<el-option :value="2" label="未通过"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin-left">搜索</el-button>
							<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
							<el-button icon="el-icon-download" type="primary" @click="exportExcel"
								v-if="permission.includes('sys:order:export')" class="ele-btn-icon search-margin-left">
								导出</el-button>
						</el-form-item>
					</el-form>
				</div>
				<!-- 数据表格 -->
				<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
					:stripe=true highlight-current-row>
					<template slot-scope="{index}">
						<el-table-column type="selection" width="45" align="center" fixed="left" />
						<el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left"
							show-overflow-tooltip />
						<el-table-column prop="title" label="标题" show-overflow-tooltip min-width="140" />
						<el-table-column prop="set_area" label="区域" show-overflow-tooltip min-width="220" />
						<el-table-column prop="amount" label="总额(元)" show-overflow-tooltip min-width="100" />
						<el-table-column prop="sur_amount" label="剩余金额(元)" show-overflow-tooltip min-width="120" />
						<el-table-column label="审核状态" show-overflow-tooltip>
							<template slot-scope="scope">
								<span>{{scope.row.is_check==0?'未审核':(scope.row.is_check==1?'通过':'未通过')}}</span>
							</template>
						</el-table-column>
						<el-table-column label="状态" show-overflow-tooltip>
							<template slot-scope="scope">
								<span>{{scope.row.status==0?'待开启':(scope.row.status==1?'已开启':(scope.row.status==2?'已结束':(scope.row.status==3?'暂停':'')))}}</span>
							</template>
						</el-table-column>
						<el-table-column label="冻结状态" show-overflow-tooltip>
							<template slot-scope="scope">
								<span>{{scope.row.zt==0?'冻结':(scope.row.zt==1?'正常':(scope.row.zt==2?'解冻':(scope.row.zt==3?'申请解冻':'解冻拒绝')))}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="add_time" label="添加时间" show-overflow-tooltip min-width="160">
							<template slot-scope="{row}">{{ row.add_time*1000 | toDateString }}</template>
						</el-table-column>
						<el-table-column prop="online_time" label="上线时间" show-overflow-tooltip min-width="160">
							<template slot-scope="{row}">{{ row.online_time*1000 | toDateString }}</template>
						</el-table-column>
						<el-table-column prop="start_time" label="开始日期" show-overflow-tooltip min-width="160">
							<template slot-scope="{row}">{{ row.start_time*1000 | toDateString }}</template>
						</el-table-column>
						<el-table-column prop="end_time" label="结束日期" show-overflow-tooltip min-width="160">
							<template slot-scope="{row}">{{ row.end_time*1000 | toDateString }}</template>
						</el-table-column>
						<el-table-column prop="ex_time" label="有效期(天)" show-overflow-tooltip min-width="100" />
						<el-table-column label="操作" width="240px" align="center" :resizable="false" fixed="right">
							<template slot-scope="{row}">
								<el-link icon="el-icon-view" type="primary" :underline="false" @click="showActive(row)">
									查看</el-link>
								<!-- <el-link icon="el-icon-document" type="primary" :underline="false"
									@click="handleRecord(row)">领取记录</el-link> -->
								<!--0为未审核，显示-->
								<el-dropdown @command="command => dropClick(command,row)" v-if="row.is_check !==2 && row.status!==2 && row.zt !==0">
									<span class="el-dropdown-link" style="margin-left: 10px;">
										<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
									</span>
									
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item icon="el-icon-document" command="handleRecord">
											领取记录
										</el-dropdown-item>
										<span v-if="row.is_check==0">
											<el-dropdown-item icon="el-icon-s-check" v-if="permission.includes('sys:change_discounts:pass')" command="isCheck">
												审核
											</el-dropdown-item>
										</span>
										<span v-if="row.is_check==1">
											<!-- <span v-if="row.status==0 || row.status==3"> -->
												<el-dropdown-item icon="el-icon-s-claim" v-if="(row.status==0 || row.status==3) && permission.includes('sys:change_discounts:pass')" command="open">
													开启
												</el-dropdown-item>
											<!-- </span> -->
												<el-dropdown-item v-if="(row.status==0 || row.status==3) && row.zt == 1 && permission.includes('sys:change_discounts:freeze')" icon="el-icon-remove-outline" command="freezeD">
													冻结
												</el-dropdown-item>
												<!-- <el-dropdown-item v-if="row.zt == 0 && permission.includes('sys:change_discounts:freeze')" icon="el-icon-remove-outline" command="freeze">
													解冻
												</el-dropdown-item> -->
												<el-dropdown-item icon="el-icon-remove-outline" v-if="row.status==1 && row.zt == 1 && permission.includes('sys:change_discounts:pass')" command="pause">
													暂停
												</el-dropdown-item>
										</span>
									</el-dropdown-menu>
								</el-dropdown>
							</template>
						</el-table-column>
					</template>
				</ele-data-table>

				<!-- 对话框：添加活动 -->
				<el-dialog v-dialogDrag title="添加零钱优惠活动" :visible.sync="dialogFormVisibleAddDiscountActivity" 
					@closed="[showEdit={},amount=0,remaining=0]" width="75%" :destroy-on-close="true" :lock-scroll="false">
					<el-card shadow="never">
						<div style="display: flex;background: white;">
							<div>
								<el-form :model="showEdit" ref="showEdit" label-width="120px" :rules="editRules">
									<el-form-item label="优惠标题:" prop="title">
										<el-input v-model="showEdit.title" autocomplete="off" class="input216"></el-input>
									</el-form-item>
									<div>
										<el-form-item label="所属区域:" prop="pid">
											<el-select v-model="showEdit.pid" placeholder="请选择省" class="selectStyle mr-10 mb-20"
												@change="handleChangeProv(showEdit.pid)" clearable>
												<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
													:label="option.name"></el-option>
											</el-select>
											<el-select v-model="showEdit.cid" placeholder="请选择市" class="selectStyle mr-10 mb-20"
												@change="handleChangeCity(showEdit.cid)" clearable>
												<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
													:label="option.name"></el-option>
											</el-select>
											<el-select v-model="showEdit.aid" placeholder="请选择县/区" class="selectStyle mb-20"
												@change="$forceUpdate()" clearable>
												<el-option v-for="option in districtArr" :value="option.aid" :key="option.aid"
													:label="option.name"></el-option>
											</el-select>
										</el-form-item>
									</div>
									<el-form-item label="所属APP：" prop="atype" style="height: 0px;overflow: hidden;margin-bottom: 0;">
										<el-select v-model="showEdit.atype" placeholder="请选择所属APP" clearable class="input216">
											<!-- <el-option label="咱县打车" :value="1" /> -->
											<el-option label="可蚁点" :value="2" />
										</el-select>
									</el-form-item>
									<el-form-item label="适用订单类型:" prop="type">
										<el-select v-model="showEdit.type" placeholder="请选择适用订单类型" clearable class="input216">
											<el-option :value="0" label="全平台"></el-option>
											<el-option :value="1" label="巡游出租"></el-option>
											<el-option :value="2" label="城际定制"></el-option>
											<el-option :value="3" label="超市"></el-option>
											<el-option :value="4" label="叫餐"></el-option>
											<el-option :value="5" label="跑腿（帮我买、取送件）"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="开始时间:" prop="start_time">
										<el-date-picker class="el-date-picker-width" v-model="showEdit.start_time"
											type="datetime" placeholder="请选择开始时间">
										</el-date-picker>
									</el-form-item>
									<el-form-item label="结束时间:" prop="end_time">
										<el-date-picker class="el-date-picker-width" v-model="showEdit.end_time" type="datetime"
											placeholder="请选择结束时间">
										</el-date-picker>
										<span style="color:#FF0000">（结束时间建议在设置活动的基础上加5分钟）</span>
									</el-form-item>
									<el-form-item label="总金额(元):" prop="amount">
										<el-input v-model="showEdit.amount" autocomplete="off" @input="onInputBlur" placeholder="单位（元）" class="input216"></el-input> 元
									</el-form-item>
									<el-form-item label="零钱有效期:" prop="ex_time">
										<el-input v-model="showEdit.ex_time" autocomplete="off" placeholder="单位（天）" class="input216"></el-input>
									</el-form-item>
								
									<div style="margin-bottom:20px;display: flex;justify-content: space-between;width:60%">
										<div>奖励设置</div>
										<div>已用金额：<span style="color:#ff0000">{{amount?Math.round(amount*100)/100:0}} 元</span></div>
										<div>剩余金额：<span style="color:#ff0000">{{remaining}} 元</span></div>
										<!-- <div>剩余金额：<span style="color:#ff0000">{{remaining?Math.round(remaining*100)/100:0}} 元</span></div> -->
									</div>
									<el-form-item label="奖项一:">
										<el-input v-model="showEdit.prize1" autocomplete="off" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur" prop="prize1">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize1_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur" prop="prize1_num"></el-input>
										<el-input v-model="showEdit.prize1_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize1_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize1_super" clearable class="input150 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项二:">
										<el-input v-model="showEdit.prize2" autocomplete="off" prop="prize2" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize2_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>

										<el-input v-model="showEdit.prize2_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize2_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize2_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项三:">
										<el-input v-model="showEdit.prize3" autocomplete="off" prop="prize3" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize3_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>

										<el-input v-model="showEdit.prize3_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize3_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize3_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项四:">
										<el-input v-model="showEdit.prize4" autocomplete="off" prop="prize4" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize4_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>

										<el-input v-model="showEdit.prize4_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize4_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize4_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项五:">
										<el-input v-model="showEdit.prize5" autocomplete="off" prop="prize5" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize5_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>

										<el-input v-model="showEdit.prize5_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize5_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize5_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项六:">
										<el-input v-model="showEdit.prize6" autocomplete="off" prop="prize6" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize6_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>

										<el-input v-model="showEdit.prize6_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize6_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize6_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项七:">
										<el-input v-model="showEdit.prize7" autocomplete="off" prop="prize7" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize7_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>

										<el-input v-model="showEdit.prize7_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize7_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize7_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>

									<el-form-item label="奖项八:">
										<el-input v-model="showEdit.prize8" autocomplete="off" placeholder="0"
											class="input120 mr-10 mb-20" @input="onNumBlur" prop="prize8">
											<template slot="prepend">￥</template>
										</el-input>
										<el-input v-model="showEdit.prize8_num" autocomplete="off" placeholder="数量"
											class="input120 mr-10 mb-20" @input="onNumBlur"></el-input>
										<el-input v-model="showEdit.prize8_name" autocomplete="off" placeholder="名称"
											class="input120 mr-10 mb-20"></el-input>
										<el-select v-model="showEdit.prize8_type" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项类型"
											@change="handlePrize()">
											<el-option label="未中奖" :value="0" />
											<el-option label="零钱" :value="2" />
										</el-select>
										<el-select v-model="showEdit.prize8_super" clearable class="input150 mr-10 mb-20" placeholder="请选择奖项状态">
											<el-option label="正常" :value="0" />
											<el-option label="特殊奖" :value="1" />
										</el-select>
									</el-form-item>
									<el-form-item style="color:#FF0000">
										提示：数量关乎中将概率，比如所有奖项总数量为10000，奖项一数量为9000，那么奖项一的中奖概率为9000/10000.
									</el-form-item>
								</el-form>
							</div>
							<!-- 活动模板 -->
							<div style="border-left: solid 1px #C6C6C6;padding-left: 40px;    width: 30%;">
								<div style="font-size: 18px;padding-bottom: 20px;">活动模板</div>
								<div style="color:rgb(51,204,153);font-size:14px;margin-bottom: 30px;">
									* 您好，活动模板内容仅供活动设置参考，可根据目前运营地区实际情况进行设置或直接采用模板内容
								</div>
								<div class="templateBox" style="padding: 0 0 20px 0;border-bottom: solid 1px #e6e6e6;">
									<div>
											<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
											阶段一：(目的：{{template.purpose}})
									</div>
									<p style="margin-top: 14px;"></p>
									<p>添加红包优惠：{{template.red}}</p>
									<p>新用户送零钱：{{template.bonus}}</p>
									<p>佣金设置：{{template.commission}}</p>
									<p style="margin-top: 14px;"></p>
									<p style="color:rgb(51,204,153);">预计拓客数：{{template.num}}</p>
									<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price}}</p>
								</div>
								<div class="templateBox" style="padding: 20px 0 20px 0;border-bottom: solid 1px #e6e6e6;">
									<div>
											<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
											阶段二：(目的：{{template.purpose1}})
									</div>
									<p style="margin-top: 14px;"></p>
									<p>添加红包优惠：{{template.red1}}</p>
									<p>新用户送零钱：{{template.bonus1}}</p>
									<p>佣金设置：{{template.commission1}}</p>
									<p style="margin-top: 14px;"></p>
									<p style="color:rgb(51,204,153);">预计拓客数：{{template.num1}}</p>
									<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price1}}</p>
								</div>
								<div class="templateBox" style="padding: 20px 0 20px 0;">
									<div>
											<i class="el-icon-location" style="color:rgb(51,204,153);font-size:16px"></i>
											阶段三：(目的：{{template.purpose2}})
									</div>
									<p style="margin-top: 14px;"></p>
									<p>添加红包优惠：{{template.red2}}</p>
									<p>新用户送零钱：{{template.bonus2}}</p>
									<p>佣金设置：{{template.commission2}}</p>
									<p style="margin-top: 14px;"></p>
									<p style="color:rgb(51,204,153);">预计拓客数：{{template.num2}}</p>
									<p style="color:rgb(51,204,153);">预计活动投入资金：{{template.price2}}</p>
								</div>
							</div>
						</div>
					</el-card>
					<div slot="footer">
						<el-button @click="dialogFormVisibleAddDiscountActivity=false">取消</el-button>
						<el-button type="primary" @click="save()">保存</el-button>
					</div>
				</el-dialog>

				<!-- 对话框：查看详情 -->
				<el-dialog v-dialogDrag title="查看详情" :visible.sync="dialogFormVisibleViewAddDiscountActivity" width="800px" :destroy-on-close="true" :lock-scroll="false">
					<el-card shadow="never">
						<el-form :model="formLabelAlign" label-width="100px">
							<el-card shadow="never">
								<el-form-item label="标题:" class="drop-down-second">
									<span>{{formLabelAlign.title}}</span>
								</el-form-item>
								<el-form-item label="所属区域:" style="width:800px;margin-right:10px">
									<span>{{formLabelAlign.set_area}}</span>
								</el-form-item>
								<!--                    <el-form-item label="关联商家:" class="drop-down-second">-->
								<!--                        <span>{{formLabelAlign.stroe}}</span>-->
								<!--                    </el-form-item>-->
								<el-form-item label="审核:" class="drop-down-second">
									<span>{{formLabelAlign.is_check==0?'未审核':(formLabelAlign.is_check==1?'通过':'未通过')}}</span>
								</el-form-item>
								<el-form-item label="状态:" class="drop-down-second">
									<span>{{formLabelAlign.status==0?'待开启':(formLabelAlign.status==1?'已开启':(formLabelAlign.status==2?'已结束':'暂停'))}}</span>
								</el-form-item>
								<el-form-item label="冻结状态:" class="drop-down-second">
									<span>{{formLabelAlign.zt==0?'冻结':(formLabelAlign.zt==1?'正常':(formLabelAlign.zt==2?'申请解冻':'解冻拒绝'))}}</span>
								</el-form-item>
								<el-form-item label="添加时间:">
									<span>{{formLabelAlign.add_time*1000 | toDateString }}</span>
								</el-form-item>
								<el-form-item label="审核时间:">
									<span>{{formLabelAlign.check_time*1000 | toDateString }}</span>
								</el-form-item>
								<el-form-item label="上线时间:">
									<span>{{formLabelAlign.online_time*1000 | toDateString}}</span>
								</el-form-item>
								<el-form-item label="总金额(元):" class="drop-down-second">
									<span>{{formLabelAlign.amount}} 元</span>
								</el-form-item>
								<el-form-item label="剩余金额(元):" class="drop-down-second">
									<span>{{formLabelAlign.sur_amount}} 元</span>
								</el-form-item>
								<el-form-item label="零钱有效期:" class="drop-down-second">
									<span>{{formLabelAlign.ex_time}} 天</span>
								</el-form-item>
								<div style="border-bottom:4px solid #e7eaec"></div>
								<div class="my-headline">奖励</div>
								<el-form-item label="奖项一:" class="span-left">
									<span>￥：{{formLabelAlign.prize1}} 元</span>
									<span>数量：{{formLabelAlign.prize1_num}}</span>
									<span>名称：{{formLabelAlign.prize1_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize1_type==0?'未中奖':(formLabelAlign.prize1_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项二:" class="span-left">
									<span>￥：{{formLabelAlign.prize2}} 元</span>
									<span>数量：{{formLabelAlign.prize2_num}}</span>
									<span>名称：{{formLabelAlign.prize2_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize2_type==0?'未中奖':(formLabelAlign.prize2_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项三:" class="span-left">
									<span>￥：{{formLabelAlign.prize3}} 元</span>
									<span>数量：{{formLabelAlign.prize3_num}}</span>
									<span>名称：{{formLabelAlign.prize3_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize3_type==0?'未中奖':(formLabelAlign.prize3_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项四:" class="span-left">
									<span>￥：{{formLabelAlign.prize4}} 元</span>
									<span>数量：{{formLabelAlign.prize4_num}}</span>
									<span>名称：{{formLabelAlign.prize4_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize4_type==0?'未中奖':(formLabelAlign.prize4_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项五:" class="span-left">
									<span>￥：{{formLabelAlign.prize5}} 元</span>
									<span>数量：{{formLabelAlign.prize5_num}}</span>
									<span>名称：{{formLabelAlign.prize5_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize5_type==0?'未中奖':(formLabelAlign.prize5_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项六:" class="span-left">
									<span>￥：{{formLabelAlign.prize6}} 元</span>
									<span>数量：{{formLabelAlign.prize6_num}}</span>
									<span>名称：{{formLabelAlign.prize6_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize6_type==0?'未中奖':(formLabelAlign.prize6_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项七:" class="span-left">
									<span>￥：{{formLabelAlign.prize7}} 元</span>
									<span>数量：{{formLabelAlign.prize7_num}}</span>
									<span>名称：{{formLabelAlign.prize7_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize7_type==0?'未中奖':(formLabelAlign.prize7_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
								<el-form-item label="奖项八:" class="span-left">
									<span>￥：{{formLabelAlign.prize8}} 元</span>
									<span>数量：{{formLabelAlign.prize8_num}}</span>
									<span>名称：{{formLabelAlign.prize8_name}}</span>
									<span>奖品类型：{{formLabelAlign.prize8_type==0?'未中奖':(formLabelAlign.prize8_type==1?'优惠券':'零钱')}}</span>
								</el-form-item>
							</el-card>
						</el-form>
					</el-card>
				</el-dialog>
				<!-- 对话框：领取记录 -->
				<el-dialog v-dialogDrag title="零钱优惠领取记录" :visible.sync="dialogVisibleChargeRecord" width="1200px" :destroy-on-close="true" :lock-scroll="false">
					<el-card shadow="never">
						<el-form :model="table.where" class="ele-form-search" @keyup.enter.native="$refs.table.reload()"
							@submit.native.prevent>
							<el-form-item class="d-flex f-end">
								<el-button icon="el-icon-download" type="primary" @click="exportExcel"
									v-if="permission.includes('sys:order:export')">导出</el-button>
							</el-form-item>
							<!-- 数据表格 -->
							<ele-data-table ref="table" :data="records" :choose.sync="choose"
								height="calc(100vh - 315px)" :stripe=true highlight-current-row>
								<template slot-scope="{index}">
									<el-table-column type="selection" width="45" align="center" fixed="left" />
									<el-table-column type="index" :index="index" label="序号" width="50" align="center"
										fixed="left" show-overflow-tooltip />
									<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="130" />

									<el-table-column prop="user_name" label="用户名称" show-overflow-tooltip
										min-width="130" />
									<el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="100" />
									<el-table-column prop="get_num" label="抽奖次数(次)" show-overflow-tooltip
										min-width="110" />
									<el-table-column prop="money" label="总抽奖金额(元)" show-overflow-tooltip min-width="120" />
									<el-table-column prop="ex_money" label="当前金额(元)" show-overflow-tooltip
										min-width="110" />
									<el-table-column prop="ex_time" label="截至有效期" show-overflow-tooltip
										min-width="220" />
								</template>
							</ele-data-table>
						</el-form>
					</el-card>
				</el-dialog>
			</el-card>
		</el-tabs>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	// import uploadImage from '@/components/uploadImage';
	// import download from '@/components/Download/index'

	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: '/finance/bonusx_list',
					where: {}
				},
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules:{
					title:[{
						required:true,
						message:'请输入优惠标题',
						trigger:'blur'
					}],
					pid:[{
						required:true,
						message:'请选择省',
						trigger: "change"
					}],
					atype:[{
						required:true,
						message:'请选择所属APP',
						trigger: "blur"
					}],
					type:[{
						required:true,
						message:'请选择适用订单类型',
						trigger: "blur"
					}],
					start_time:[{
						type: 'date',
						required:true,
						message:'请选择开始时间',
						trigger: "blur"
					}],
					end_time:[{
						type: 'date',
						required:true,
						message:'请选择结束时间',
						trigger: "blur"
					}],
					amount:[{
						required:true,
						message:'请输入总金额',
						trigger:'blur'
					},{
						pattern: /^([1-9][0-9]*)+(.[0-9]{1,2})?$/, 
						message: '请输入大于1的有效数字', 
					}],
					ex_time:[{
						required:true,
						message:'请输入零钱有效期',
						trigger:'blur'
					},{
						pattern: /^[0-9]*$/, 
						message: '请输入有效天数', 
					}],
					record:[{
						required:true,
						message:'请设置奖励',
						trigger:'blur'
					}]
				},
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				showEdit: {
					atype:2,
				},
				dialogFormVisibleAddDiscountActivity: false,
				dialogFormVisibleViewAddDiscountActivity: false,
				dialogVisibleChargeRecord: false,
				formLabelAlign: {},
				row: [{
						state: '开启'
					},
					{
						state: '关闭'
					}
				],
				amount: 0,
				remaining: 0,
				records: [],
				template:[],
				p1:0,
				p2:0,
				p3:0,
				p4:0,
				p5:0,
				p6:0,
				p7:0,
				p8:0,
				p9:0,
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
		this.$http.get('/finance/activity_template').then(res=>{
			this.template = res.data.data
		})
		},

		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {
		},
		methods: {
			handleRecord(row) {
				this.dialogVisibleChargeRecord = true
				this.$http.post('/finance/lottery_list', {
					bonusx_id: row.id
				}).then(res => {
					this.records = res.data.data
				})
			},
			dropClick(command, row) {
				if (command === 'isCheck') { //审核
					this.handleIscheck(row)
				} else if (command === 'freezeD') { //冻结
					this.handleFreeze(row.id,0)
				} else if (command === 'freeze') { //解冻
					this.handleFreeze(row.id,2)
				} else if (command === 'open') { //开启
					this.handleClass(row)
				} else if (command === 'pause') { //暂停
					this.handleRecords(row)
				} else if(command ==='handleRecord'){ //领取记录
					this.handleRecord(row)
				}
			},
			exportExcel() {},
			onNumBlur() {
				this.getSum()
			},
			handlePrize() {
				this.getSum()
			},
			getSum() {
				let sum, prize1, prize2, prize3, prize4, prize5, prize6, prize7, prize8
				if (this.showEdit.prize1 && this.showEdit.prize1_num && this.showEdit.prize1_type == 2) {
					prize1 = this.cal.accMul(this.showEdit.prize1,this.showEdit.prize1_num) //金额*数量
				} else {
					prize1 = 0
				}
				if (this.showEdit.prize2 && this.showEdit.prize2_num && this.showEdit.prize2_type == 2) {
					prize2 = this.cal.accMul(this.showEdit.prize2,this.showEdit.prize2_num) //金额*数量
				} else {
					prize2 = 0
				}
				if (this.showEdit.prize3 && this.showEdit.prize3_num && this.showEdit.prize3_type == 2) {
					prize3 = this.cal.accMul(this.showEdit.prize3,this.showEdit.prize3_num) //金额*数量
				} else {
					prize3 = 0
				}
				if (this.showEdit.prize4 && this.showEdit.prize4_num && this.showEdit.prize4_type == 2) {
					prize4 = this.cal.accMul(this.showEdit.prize4,this.showEdit.prize4_num) //金额*数量
				} else {
					prize4 = 0
				}
				if (this.showEdit.prize5 && this.showEdit.prize5_num && this.showEdit.prize5_type == 2) {
					prize5 = this.cal.accMul(this.showEdit.prize5,this.showEdit.prize5_num) //金额*数量
				} else {
					prize5 = 0
				}
				if (this.showEdit.prize6 && this.showEdit.prize6_num && this.showEdit.prize6_type == 2) {
					prize6 = this.cal.accMul(this.showEdit.prize6,this.showEdit.prize6_num) //金额*数量
				} else {
					prize6 = 0
				}
				if (this.showEdit.prize7 && this.showEdit.prize7_num && this.showEdit.prize7_type == 2) {
					prize7 = this.cal.accMul(this.showEdit.prize7,this.showEdit.prize7_num) //金额*数量
				} else {
					prize7 = 0
				}
				if (this.showEdit.prize8 && this.showEdit.prize8_num && this.showEdit.prize8_type == 2) {
					prize8 = this.cal.accMul(this.showEdit.prize8,this.showEdit.prize8_num) //金额*数量
				} else {
					prize8 = 0
				}
				sum = 
				this.cal.accAdd(
				this.cal.accAdd(this.cal.accAdd(prize1,prize2),this.cal.accAdd(prize3,prize4))
				,
				this.cal.accAdd(this.cal.accAdd(prize5,prize6),this.cal.accAdd(prize7,prize8))
				)
				if (prize1 || prize2 || prize3 || prize4 || prize5 || prize6 || prize7 || prize8 && this.showEdit.amount) {
					this.amount = sum
					if(this.cal.accSub(sum,this.showEdit.amount)< 0){
						this.$message.error('请注意，您的剩余金额不足');
						this.remaining = this.cal.accSub(sum,this.showEdit.amount)
					}else if(this.cal.accSub(sum,this.showEdit.amount) == 0){
						this.remaining = 0
					}else{
						this.remaining = this.cal.accSub(sum,this.showEdit.amount)
					}
				}
				this.p1 = prize1
				this.p2 = prize2
				this.p3 = prize3
				this.p4 = prize4
				this.p5 = prize5
				this.p6 = prize6
				this.p7 = prize7
				this.p8 = prize8
				return sum
			},
			onInputBlur() {
				var sum = this.p1 + this.p2 + this.p3 + this.p4 + this.p5 + this.p6 + this.p7 + this.p8
				if(/^([1-9][0-9]*)+(.[0-9]{1,2})?$/.test(this.showEdit.amount)){
					this.remaining = this.showEdit.amount
					if (this.p1 || this.p2 || this.p3 || this.p4 || this.p5 || this.p6 || this.p7 || this.p8) {
						if(this.cal.accSub(sum,this.showEdit.amount) < 0){
							this.$message.error('请注意，您的剩余金额不足');
							this.remaining = this.cal.accSub(sum,this.showEdit.amount)
						}else{
							this.remaining = this.cal.accSub(sum,this.showEdit.amount)
						}
					}
				}
			},
			save() {
				this.$refs['showEdit'].validate((valid) => {
					//console.log(this.remaining)
					if (valid) {
						if(this.remaining > 0){
							this.$message.error('请注意，您还有未使用的剩余金额');
						}else if(this.remaining < 0){
							this.$message.error('请注意，您的剩余金额不足');
						}else if(!this.showEdit.prize1 || !this.showEdit.prize1_name || !this.showEdit.prize1_num || 
						(this.showEdit.prize1_super !==0 && this.showEdit.prize1_super !==1) || 
						(this.showEdit.prize1_type !==0 && this.showEdit.prize1_type !==2) ||
						!this.showEdit.prize2 || !this.showEdit.prize2_name || !this.showEdit.prize2_num || 
						(this.showEdit.prize2_super !==0 && this.showEdit.prize2_super!==1) || 
						(this.showEdit.prize2_type !==0 && this.showEdit.prize2_type !==2) ||
						!this.showEdit.prize3 || !this.showEdit.prize3_name || !this.showEdit.prize3_num || 
						(this.showEdit.prize3_super !==0 && this.showEdit.prize3_super!==1) || 
						(this.showEdit.prize3_type !==0 && this.showEdit.prize3_type !==2) ||
						!this.showEdit.prize4 || !this.showEdit.prize4_name || !this.showEdit.prize4_num || 
						(this.showEdit.prize4_super !==0 && this.showEdit.prize4_super!==1) || 
						(this.showEdit.prize4_type !==0 && this.showEdit.prize4_type !==2) ||
						!this.showEdit.prize5 || !this.showEdit.prize5_name || !this.showEdit.prize5_num || 
						(this.showEdit.prize5_super !==0 && this.showEdit.prize5_super!==1) || 
						(this.showEdit.prize5_type !==0 && this.showEdit.prize5_type !==2) ||
						!this.showEdit.prize6 || !this.showEdit.prize6_name || !this.showEdit.prize6_num || 
						(this.showEdit.prize6_super !==0 && this.showEdit.prize6_super!==1) || 
						(this.showEdit.prize6_type !==0 && this.showEdit.prize6_type !==2) ||
						!this.showEdit.prize7 || !this.showEdit.prize7_name || !this.showEdit.prize7_num || 
						(this.showEdit.prize7_super !==0 && this.showEdit.prize7_super!==1) || 
						(this.showEdit.prize7_type !==0 && this.showEdit.prize7_type !==2) ||
						!this.showEdit.prize8 || !this.showEdit.prize8_name || !this.showEdit.prize8_num || 
						(this.showEdit.prize8_super !==0 && this.showEdit.prize8_super!==1) || 
						(this.showEdit.prize8_type !==0 && this.showEdit.prize8_type !==2))
						{
							this.$message.error('请注意，您有未填写的奖励设置');
							//console.log('this.showEdit',this.showEdit)
						}else{
							const loading = this.$loading({lock: true});
							this.$http.post('finance/bonusx_save', this.showEdit).then(res => {
								loading.close()
								if (res.data.code === 0) {
									this.$message({
										type: 'success',
										message: res.data.msg
									});
									this.amount = 0
									this.remaining = 0
									this.$refs.table.reload();
									this.dialogFormVisibleAddDiscountActivity = false
								} else {
									this.$message.error(res.data.msg);
								}
							}).catch(e => {
								loading.close();
								this.$message.error(e.message);
							});
						}
					} else {
							return false;
						}
				});
			},
			showActive(row) {
				this.dialogFormVisibleViewAddDiscountActivity = true
				this.formLabelAlign = row
			},

			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			// 审核
			handleIscheck(row) {
				this.$confirm('是否审核通过该申请?', '提示', {
					type: 'warning',
					distinguishCancelAndClose: true,
					confirmButtonText: '通过',
					cancelButtonText: '驳回',
				}).then(() => {
					const loading = this.$loading({ lock: true });
					this.$http.post('/finance/bonusx_review', {id: row.id,is_check: 1}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({type: 'success',message: res.data.msg});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					})	
				}).catch(e => {
					//console.log(e)
					const loading = this.$loading({ lock: true });
					this.$http.post('/finance/bonusx_review', {id: row.id,is_check: 3}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({type: 'success',message: res.data.msg});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					});
				})
			},
			// 冻结
			handleFreeze(id,zt) {
				if(zt == 0){
					this.$confirm('您确定要冻结此活动?', '提示', {
						type: 'warning',
						confirmButtonText: '通过',
						cancelButtonText: '取消',
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/finance/bonusx_freeze', {
							id: id,
							zt: zt
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
				if(zt == 2){
					this.$confirm('您确定要解冻此活动?', '提示', {
						type: 'warning',
						confirmButtonText: '通过',
						cancelButtonText: '取消',
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/finance/bonusx_freeze', {
							id: id,
							zt: zt
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
				
			},
			//暂停
			handleRecords(row) {
				this.$confirm('确定暂停改活动吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/bonusx_status', {
						id: row.id,
						status: 3
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			//开启
			handleClass(row) {
				this.$confirm('您确定要开启该活动?', '提示', {
					type: 'warning',
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/finance/bonusx_status', {
						id: row.id,
						status: 1
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
		}
	}
</script>

<style scoped>
	.drop-down-first {
		width: 140px;
	}
	.templateBox p{
		padding: 4px 0 0 0;
	}
	/deep/.el-dialog{
		margin-top: 30px !important;
		width: 60%;
	}
	/deep/.el-input-group__prepend{
		padding: 0 8px;
	}
</style>
